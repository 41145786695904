import React from 'react';
import Link from 'gatsby-link';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import ProjectListRow from './ProjectListRow';
import Subhead from './Subhead';
import Grid from './Grid';

const ProjectHeader = ({ name, subhead, sectionid = '' }) => {
  const [titleAnimation, setTitleAnimation] = useSpring(() => ({
    from: { xy: [-32, 0] },
    to: { xy: [0, 0] },
  }));

  const animateLeft = () => setTitleAnimation({ xy: [-32, 0] });

  const resetAnimation = () => setTitleAnimation({ xy: [0, 0] });

  return (
    <Grid>
      <ProjectListRow layout="full">
        <TitleAndSubheadWithIcon>
          <BackIcon>
            <svg width="70" height="50" viewBox="0 0 70 50">
              <path d="M5.787 26.5l21.355 21.355L25 49.998l-25-25L25 0l2.142 2.143L5.784 23.5H70v3H5.787" />
            </svg>
          </BackIcon>

          <TitleAndSubhead
            to={`/#${sectionid}`}
            onMouseEnter={animateLeft}
            onMouseLeave={resetAnimation}
          >
            <animated.div
              style={{
                transform: titleAnimation.xy.interpolate(
                  (x, y) => `translate3d(${x}px, ${y}px, 0px)`
                ),
              }}
            >
              <BoldTitle>{name}</BoldTitle>
              {subhead && (
                <>
                  {' '}
                  <Subhead>{subhead}</Subhead>
                </>
              )}
            </animated.div>
          </TitleAndSubhead>

          <CloseIcon to={`/#${sectionid}`}>
            <svg width="50" height="50" viewBox="0 0 50 50">
              <path d="M27.143 25L50 2.142 47.855 0 25 22.856 2.144 0 0 2.143 22.858 25 0 47.854l2.144 2.143L25 27.142l22.856 22.856L50 47.855 27.142 25" />
            </svg>
          </CloseIcon>
        </TitleAndSubheadWithIcon>
      </ProjectListRow>
    </Grid>
  );
};

export default ProjectHeader;

const TitleAndSubheadWithIcon = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  @media screen and (any-hover: hover) and (min-width: 600px) {
    text-align: center;
  }
`;

const BackIcon = styled.div`
  display: none;
  align-items: center;
  margin-right: 1rem;

  @media (any-hover: hover) {
    display: flex;
  }

  @media screen and (any-hover: hover) and (min-width: 600px) {
    position: absolute;
    left: 0;
  }

  svg {
    display: block;
    fill: var(--theme-primary);

    @media screen and (max-width: 600px) {
      height: 2rem;
    }
  }
`;

const TitleAndSubhead = styled(Link)`
  display: block;
  flex: 2;
  padding: 0.75em 0;
  text-decoration: none;
  cursor: w-resize;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

const BoldTitle = styled.h1`
  font-weight: 900;
  font-size: inherit;
`;

const CloseIcon = styled(Link)`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-left: 1rem;
  padding: 0.75em 0;

  @media (any-hover: hover) {
    display: none;
  }

  svg {
    display: block;
    height: 2rem;
    fill: var(--theme-primary);
  }
`;
